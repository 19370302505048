<template>
  <div class="container teacher-style">
    <div class="bg">
      <div class="img">
        <img
          src="@/assets/Rectangle 2706.png"
          class="d-block ver-bottom"
          alt=""
        />
      </div>
    </div>
    <div class="b-r24 bg-fff info po-r co_333">
      <div class="d-flex align-items-end">
        <div class="img b-r12">
          <img :src="obj.detailsIcon" alt="" />
        </div>
        <div class="text">
          <p class="title f-w700">{{ obj.netName }}</p>
          <p class="tags">{{ obj.title }}</p>
        </div>
      </div>
      <div class="info-tips" v-html="obj.represent">
        <!-- <p>{{ r }}</p> -->
      </div>
    </div>
    <video
      v-if="obj.video"
      :src="obj.video"
      controls
      :poster="obj.video + `?x-oss-process=video/snapshot,t_3000,m_fast`"
      ref="refVideo"
      id="upvideo"
      controlslist="nodownload"
      x-webkit-airplay="allow"
      webkit-playsinline="true"
      x5-playsinline="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="false"
      playsinline="true"
      preload="auto"
      x5-video-orientation="h5"
      style="width: 100%; height: 100%; object-fit: fill; margin: 16px 0 0"
    >
      此浏览器不支持video标签
    </video>
    <div class="b-r24 bg-fff content">
      <p class="title co_333 text-center">加老师微信 制订计划免费领取资料包</p>
      <div class="img d-flex justify-content-center align-items-center">
        <img class="left" src="@/assets/left.png" alt="" />
        <div class="qr-code">
          <img :src="wxCode" alt="" class="code" />
        </div>
        <img class="right" src="@/assets/right.png" alt="" />
      </div>
      <div class="step">
        <div class="step-icon d-flex align-items-center justify-content-center">
          <img src="@/assets/Group 427318266.png" alt="" />
          <img src="@/assets/Group 427318269.png" alt="" class="p-19" />
          <img src="@/assets/Group 427318267.png" alt="" />
          <img src="@/assets/Group 427318269.png" alt="" class="p-19" />
          <img src="@/assets/Group 427318268.png" alt="" />
        </div>
        <div class="step-text d-flex align-items-center justify-content-center">
          <div class="text-center">
            <p class="f-w700 co_333">长按/截图</p>
            <p class="co_666">保存二维码</p>
          </div>
          <div class="text-center">
            <p class="f-w700 co_333">打开微信扫一扫</p>
            <p class="co_666">识别相册二维码</p>
          </div>
          <div class="text-center">
            <p class="f-w700 co_333">添加老师</p>
            <p class="co_666">领资料上课</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTeacherStyleById,
  getTeacherStyleVOList,
  getTeacherQrCode,
} from "@/api/teacher";

export default {
  name: "",
  data() {
    return {
      videoPoster: "",
      obj: {},
      wxCode: "",
    };
  },

  created() {
    console.log(this.$route.query.type,544545);
    this.getTeacherQrCode();
  },
  mounted() {
    if (this.$route.query.id) {
      this.getTeacherStyleById({
        id: this.$route.query.id,
      });
    }

    // getTeacherStyleVOList()
  },
  methods: {
    async getTeacherStyleById(id) {
      const { data, code } = await getTeacherStyleById(id);
      if (code != "SUCCESS") return;
      this.obj = data;
      // this.obj.represent = data.represent.replace(/([a-z])+/g, ' ')
      // this.obj.represent = data.represent.replace('\n', '')
      // this.findvideocover()
    },
    // 获取老师二维码
    getTeacherQrCode() {
      getTeacherQrCode({ type: this.$route.query.type || ""  , application : this.$route.query.application}).then((res) => {
        if (res.code != "SUCCESS") return;
        this.wxCode = res.data;
      });
    },
    // 获取第一帧
    findvideocover() {
      let _this = this;
      this.$nextTick(() => {
        let video = document.getElementById("upvideo");
        let source = document.createElement("source");
        // source.src = require("../../assets/5b086751dbb7af1ea8fa8d05e66fe5c3.mp4");this.formLabelAlign.video
        source.src = this.obj.video;
        source.type = "video/mp4";
        video.appendChild(source);
        video.addEventListener("loadedmetadata", function () {
          var canvas = document.createElement("canvas");
          canvas.width = 320;
          canvas.height = 320;
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, canvas.width, canvas.width);
          var img = document.createElement("img");
          let imgsrc = canvas.toDataURL("image/jpeg");
          this.videoPoster = imgsrc;
          // _this.Videoframehandle(imgsrc.split(",")[1]);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher-style {
  background: #f5f6f9;
  min-height: 100vh;
  overflow: hidden;
  .bg {
    width: 100%;
    height: 126px;
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(211, 245, 255, 0.32) 0%,
      #b6b0ff 116.71%
    );
    .img {
      position: absolute;
      right: 0;
      bottom: 0;
      img {
        // width: 125px;
        width: 100%;
      }
    }
  }
  .info {
    margin-top: -19px;
    padding-bottom: 20px;
    z-index: 2;
    .img {
      margin: -48px 16px 17px 27px;
      box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
      background: #f5f6fa;

      img {
        width: 126px;
        height: 130px;
        object-fit: cover;
        border-radius: 12px;
        display: block;
      }
    }
    .text {
      margin-bottom: 17px;
      .title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 4px;
      }
      .tags {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .info-tips {
      font-size: 14px;
      line-height: 165%;
      letter-spacing: 2.8px;
      padding: 0 4px 0 18px;
      text-indent: 2em;
      p {
        position: relative;
        padding-left: 16px;
        line-height: 22px;
        &::before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          display: block;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #9ab9ff;
        }
      }
    }
  }
  .content {
    margin: 20px 0;
    padding: 24px 20px;
    .title {
      font-size: 18px;
      margin-bottom: 28px;
    }
    .img {
      margin-bottom: 30px;
      .left {
        width: 80px;
        margin-right: 13px;
      }
      .right {
        width: 85px;
        margin-left: 8px;
      }
      .qr-code {
        width: 123px;
        height: 124px;
        border-radius: 9px;
        background: linear-gradient(0deg, #ffd7d7, #ffd7d7), #c4c4c4;
        box-sizing: border-box;
      }
      .code {
        border-radius: 7px;
        width: 111px;
        height: 112px;
        background: #fff;
        margin: 6px;
      }
    }
    .step {
      .p-19 {
        padding: 0 19px;
      }
      &-icon {
        margin-bottom: 12px;
        img {
          &:nth-child(odd) {
            width: 36px;
          }
          &:nth-child(even) {
            width: 23px;
          }
        }
      }
      &-text {
        font-size: 12px;
        .text-center {
          p {
            line-height: 17px;
          }
          &:nth-child(2) {
            margin-left: 27px;
            margin-right: 23px;
          }
        }
      }
    }
  }
}
</style>
